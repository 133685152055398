// CONTAINER DETAILS
export const SET_CLIENT = "SET_CLIENT";
export const SET_TYPE = "SET_TYPE";
export const SET_CONTAINER_NUMBER = "SET_CONTAINER_NUMBER";
export const SET_SIZE = "SET_SIZE";
export const SET_PAYLOAD = "SET_PAYLOAD";
export const SET_GROSS_WEIGHT = "SET_GROSS_WEIGHT";
export const SET_TARE_WEIGHT = "SET_TARE_WEIGHT";
export const SET_MANUFACTURING_DATE = "SET_MANUFACTURING_DATE";
export const SET_SHIPPING_LINE = "SET_SHIPPING_LINE";
export const CLEAR_SHIPPING_LINE = "CLEAR_SHIPPING_LINE";
export const SET_SHIPPING_LINE_MENU_ITEMS = "SET_SHIPPING_LINE_MENU_ITEMS";
export const SET_GATE_IN_DATE_TIME = "SET_GATE_IN_DATE_TIME";
export const SET_LEASED_BOX = "SET_LEASED_BOX";
export const SET_DO_NOT_LIFT = "SET_DO_NOT_LIFT";
export const SET_GATE_IN_AUTO_MNR = "SET_GATE_IN_AUTO_MNR";
export const RESET_GET_IN_CONTAINER = "RESET_GET_IN_CONTAINER";
export const MNR_CONTAINER_SEARCH = "MNR_CONTAINER_SEARCH";
export const MNR_PROCESS_BY_EDIT = "MNR_PROCESS_BY_EDIT";
export const MNR_PROCESS_UNLOCK = "MNR_PROCESS_UNLOCK";

// EIR DETAILS
export const SET_EIR_DATE = "SET_EIR_DATE";
export const SET_EIR_TIME = "SET_EIR_TIME";
export const SET_EIR_AMOUNT = "SET_EIR_AMOUNT";
export const SET_OFFLOAD_DATE = "SET_OFFLOAD_DATE";
export const SET_OFFLOAD_TIME = "SET_OFFLOAD_TIME";
export const SET_REPAIR_AMOUNT = "SET_REPAIR_AMOUNT";
export const SET_ADD_EIR_LINE = "SET_ADD_EIR_LINE";
export const SET_REMOVE_EIR_LINE = "SET_REMOVE_EIR_LINE";
export const SET_EIR_SHIPPING_LINE_DAMAGE_CODE =
  "SET_EIR_SHIPPING_LINE_DAMAGE_CODE";
export const SET_EIR_SHIPPING_LINE_DESCRIPTION =
  "SET_EIR_SHIPPING_LINE_DESCRIPTION";

// Gate In Details

export const SET_IN_DATE = "SET_IN_DATE";
export const SET_IN_TIME = "SET_IN_TIME";
export const SET_CONDITION = "SET_CONDITION";
export const SET_GRADE = "SET_GRADE";
export const SET_ARRIVED = "SET_ARRIVED";
export const SET_CONSIGNOR = "SET_CONSIGNOR";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_SHIPPER = "SET_SHIPPER";
export const SET_SOURCE = "SET_SOURCE";
export const SET_FROM_LOCATION_CODE = "SET_FROM_LOCATION_CODE";
export const SET_FROM_PORT_CODE = "SET_FROM_PORT_CODE";
export const SET_VESSEL_NAME = "SET_VESSEL_NAME";
export const SET_VOYAGE_NUMBER = "SET_VOYAGE_NUMBER";
export const SET_DO_REF = "SET_DO_REF";
export const SET_IMPORT_CARGO = "SET_IMPORT_CARGO";
export const SET_EXPORT_CARGO_TYPE = "SET_EXPORT_CARGO_TYPE";
export const SET_TRANSPORTER_NAME = "SET_TRANSPORTER_NAME";
export const SET_VEHICLE_NUMBER = "SET_VEHICLE_NUMBER";
export const SET_BL_NUMBER = "SET_BL_NUMBER";
export const SET_DRIVER_NAME = "SET_DRIVER_NAME";
export const SET_DRIVER_MOBILE = "SET_DRIVER_MOBILE";
export const SET_DRIVER_LICENSE_NUMBER = "SET_DRIVER_LICENSE_NUMBER";
export const SET_CARRIER_CODE = "SET_CARRIER_CODE";
export const SET_LOCATION = "SET_LOCATION";
export const SET_TRANSPORTATION_MODULE = "SET_TRANSPORTATION_MODULE";
export const SET_LOADED_EMPTY_YARD_MODULE = "SET_LOADED_EMPTY_YARD_MODULE";
export const SET_NEW_BILLING_MODULE = "SET_NEW_BILLING_MODULE";
export const SET_REMARK = "SET_REMARK";
export const SET_DRIVER_IMAGE ="SET_DRIVER_IMAGE";

// PAYMENT DETAILS LOLO

export const LOLO_APPLY_CHARGES = "LOLO_APPLY_CHARGES";
export const LOLO_INVOICE_DATE = "LOLO_INVOICE_DATE";
export const LOLO_RECEIPT_DATE = "LOLO_RECEIPT_DATE";
export const LOLO_CUSTOMER_NAME = "LOLO_CUSTOMER_NAME";
export const LOLO_TYPE = "LOLO_TYPE";
export const LOLO_PAYMENT_TYPE = "LOLO_PAYMENT_TYPE";
export const LOLO_AMOUNT = "LOLO_AMOUNT";
export const LOLO_NIGHT_CHARGE_AMOUNT = "LOLO_NIGHT_CHARGE_AMOUNT"
export const LOLO_REMARK = "LOLO_REMARK";
export const LOLO_PAYMENT_DATE = "LOLO_PAYMENT_DATE";
export const LOLO_PAYMENT_BANK_NAME = "LOLO_PAYMENT_BANK_NAME";
export const LOLO_PAYMENT_ACCOUNT_NAME = "LOLO_PAYMENT_ACCOUNT_NAME";
export const LOLO_PAYMENT_ACCOUNT_NUMBER = "LOLO_PAYMENT_ACCOUNT_NUMBER";
export const LOLO_PAYMENT_CHEQUE_NUMBER = "LOLO_PAYMENT_CHEQUE_NUMBER";
export const LOLO_PAYMENT_UTR_NO = "LOLO_PAYMENT_UTR_NO";
export const LOLO_PAYMENT_CHEQUE_AMOUNT = "LOLO_PAYMENT_CHEQUE_AMOUNT";
export const LOLO_PAYMENT_QUANTITY = "LOLO_PAYMENT_QUANTITY";
export const LOLO_PAYMENT_WITH_GST = "LOLO_PAYMENT_WITH_GST";
export const LOLO_PAYMENT_GST = "LOLO_PAYMENT_GST";
export const GST_TYPE = "GST_TYPE";
export const LOLO_NIGHT_CHARGES = "LOLO_NIGHT_CHARGES";
export const UPDATE_LOLO_PAYMENT_CHEQUE_UTR_SEARCH_RESULT =
  "UPDATE_LOLO_PAYMENT_CHEQUE_UTR_SEARCH_RESULT";

// SELF TRANSPORTATION
export const TRANSPORTATION_APPLY_CHARGES = "TRANSPORTATION_APPLY_CHARGES";
export const TRANSPORTATION_CUSTOMER_NAME = "TRANSPORTATION_CUSTOMER_NAME";
export const TRANSPORTATION_TRANSPORTER = "TRANSPORTATION_TRANSPORTER";
export const TRANSPORTATION_RECEIPT_DATE = "TRANSPORTATION_RECEIPT_DATE";
export const TRANSPORTATION_ORIGIN = "TRANSPORTATION_ORIGIN";
export const TRANSPORTATION_PAYMENT_TYPE = "TRANSPORTATION_PAYMENT_TYPE";
export const TRANSPORTATION_PRICE = "TRANSPORTATION_PRICE";
export const TRANSPORTATION_REMARK = "TRANSPORTATION_REMARK";
export const TRANSPORTATION_PAYMENT_DATE = "TRANSPORTATION_PAYMENT_DATE";
export const TRANSPORTATION_PAYMENT_BANK_NAME =
  "TRANSPORTATION_PAYMENT_BANK_NAME";
export const TRANSPORTATION_PAYMENT_ACCOUNT_NAME =
  "TRANSPORTATION_PAYMENT_ACCOUNT_NAME";
export const TRANSPORTATION_PAYMENT_CHEQUE_NUMBER =
  "TRANSPORTATION_PAYMENT_CHEQUE_NUMBER";
export const TRANSPORTATION_PAYMENT_UTR_NO = "TRANSPORTATION_PAYMENT_UTR_NO";
export const TRANSPORTATION_PAYMENT_CHEQUE_AMOUNT =
  "TRANSPORTATION_PAYMENT_CHEQUE_AMOUNT";
export const TRANSPORTATION_PAYMENT_QUANTITY =
  "TRANSPORTATION_PAYMENT_QUANTITY";
export const TRANSPORTATION_PAYMENT_WITH_GST =
  "TRANSPORTATION_PAYMENT_WITH_GST";

export const TRANSPORTATION_GST_TYPE = "TRANSPORTATION_GST_TYPE";
export const UPDATE_SELF_TRANSPORT_PAYMENT_CHEQUE_UTR_SEARCH_RESULT =
  "UPDATE_SELF_TRANSPORT_PAYMENT_CHEQUE_UTR_SEARCH_RESULT";

// ********* EDIT CONTAINER FORM *****************

//EDIT  CONTAINER DETAILS
export const EDIT_CLIENT_NAME = "EDIT_CLIENT_NAME";
export const EDIT_CONTAINER_NUMBER = "EDIT_CONTAINER_NUMBER";
export const EDIT_CONTAINER_SIZE = "EDIT_CONTAINER_SIZE";
export const EDIT_CONTAINER_TYPE = "EDIT_CONTAINER_TYPE";
export const EDIT_CONTAINER_PAYLOAD = "EDIT_CONTAINER_PAYLOAD";
export const EDIT_GROSS_WEIGHT = "EDIT_GROSS_WEIGHT";
export const EDIT_TARE_WEIGHT = "EDIT_TARE_WEIGHT";
export const EDIT_MANUFACTURING_DATE = "EDIT_MANUFACTURING_DATE";

// EDIT EIR DETAILS
export const EDIT_REMOVE_EIR_LINE = "EDIT_REMOVE_EIR_LINE";
export const EDIT_ADD_EIR_LINE = "EDIT_ADD_EIR_LINE";

export const EDIT_EIR_DAMAGE_CODE = "EDIT_EIR_DAMAGE_CODE";
export const EDIT_EIR_LINE_DESCRIPTION = "EDIT_EIR_LINE_DESCRIPTION";
export const EDIT_EIR_DATE = "EDIT_EIR_DATE";
export const EDIT_EIR_TIME = "EDIT_EIR_TIME";
export const EDIT_EIR_AMOUNT = "EDIT_EIR_AMOUNT";
export const EDIT_OFFLOAD_DATE = "EDIT_OFFLOAD_DATE";
export const EDIT_OFFLOAD_TIME = "EDIT_OFFLOAD_TIME";
export const EDIT_REPAIR_AMOUNT = "EDIT_REPAIR_AMOUNT";

// EDIT GATE IN DETAILS

export const EDIT_GATE_IN_DATE = "EDIT_GATE_IN_DATE";
export const EDIT_GATE_IN_TIME = "EDIT_GATE_IN_TIME";

export const EDIT_GATE_IN_CONDITION = "EDIT_GATE_IN_CONDITION";
export const EDIT_GATE_IN_GRADE = "EDIT_GATE_IN_GRADE";
export const EDIT_GATE_IN_ARRIVED = "EDIT_GATE_IN_ARRIVED";

export const EDIT_GATE_IN_CONSIGNOR = "EDIT_GATE_IN_CONSIGNOR";
export const EDIT_GATE_IN_CUSTOMER = "EDIT_GATE_IN_CUSTOMER";
export const EDIT_GATE_IN_SHIPPER = "EDIT_GATE_IN_SHIPPER";
export const EDIT_GATE_IN_SOURCE = "EDIT_GATE_IN_SOURCE";
export const EDIT_FROM_LOCATION_CODE = "EDIT_FROM_LOCATION_CODE";
export const EDIT_FROM_PORT_CODE = "EDIT_FROM_PORT_CODE";
export const EDIT_VESSEL_NAME = "EDIT_VESSEL_NAME";
export const EDIT_VOYAGE_NUMBER = "EDIT_VOYAGE_NUMBER";
export const EDIT_DO_REF = "EDIT_DO_REF";
export const EDIT_IMPORT_CARGO = "EDIT_IMPORT_CARGO";
export const EDIT_EXPORT_CARGO_TYPE = "EDIT_EXPORT_CARGO_TYPE";
export const EDIT_TRANSPORTER_NAME = "EDIT_TRANSPORTER_NAME";
export const EDIT_VEHICLE_NUMBER = "EDIT_VEHICLE_NUMBER";
export const EDIT_BL_NUMBER = "EDIT_BL_NUMBER";
export const EDIT_DRIVER_NAME = "EDIT_DRIVER_NAME";
export const EDIT_DRIVER_MOBILE = "EDIT_DRIVER_MOBILE";
export const EDIT_DRIVER_LICENSE_NUMBER = "EDIT_DRIVER_LICENSE_NUMBER";
export const EDIT_CARRIER_CODE = "EDIT_CARRIER_CODE";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const EDIT_GATE_IN_REMARK = "EDIT_GATE_IN_REMARK";
export const EDIT_DRIVER_LICENSE_IMAGE ="EDIT_DRIVER_LICENSE_IMAGE"

// EDIT LOLO
export const EDIT_LOLO_INVOICE_DATE = "EDIT_LOLO_INVOICE_DATE";
export const EDIT_LOLO_RECEIPT_DATE = "EDIT_LOLO_RECEIPT_DATE";
export const EDIT_LOLO_PAYMENT_TYPE = "EDIT_LOLO_PAYMENT_TYPE";
export const EDIT_LOLO_TYPE = "EDIT_LOLO_TYPE";
export const EDIT_LOLO_AMOUNT = "EDIT_LOLO_AMOUNT";
export const EDIT_LOLO_REMARK = "EDIT_LOLO_REMARK";
export const EDIT_LOLO_CHEQUE_NUMBER = "EDIT_LOLO_CHEQUE_NUMBER";
export const EDIT_LOLO_QUANTITY = "EDIT_LOLO_QUANTITY";
export const EDIT_LOLO_UTR_NUMBER = "EDIT_LOLO_UTR_NUMBER";
export const EDIT_LOLO_CHEQUE_AMOUNT = "EDIT_LOLO_CHEQUE_AMOUNT";
export const EDIT_LOLO_BANK_NAME = "EDIT_LOLO_BANK_NAME";
export const EDIT_LOLO_CHEQUE_DATE = "EDIT_LOLO_CHEQUE_DATE";
export const EDIT_LOLO_ACCOUNT_NAME = "EDIT_LOLO_ACCOUNT_NAME";
export const EDIT_LOLO_ORIGINAL_QUANTITY = "EDIT_LOLO_ORIGINAL_QUANTITY";
export const EDIT_LOLO_ORIGINAL_AMOUNT = "EDIT_LOLO_ORIGINAL_AMOUNT";


// EDIT SELF TRANSPORT

export const EDIT_SELF_TRANSPORT_CUSTOMER_NAME =
  "EDIT_SELF_TRANSPORT_CUSTOMER_NAME";
export const EDIT_SELF_TRANSPORT_RECEIPT_DATE =
  "EDIT_SELF_TRANSPORT_RECEIPT_DATE";
export const EDIT_SELF_TRANSPORT_PAYMENT_TYPE =
  "EDIT_SELF_TRANSPORT_PAYMENT_TYPE";
export const EDIT_SELF_TRANSPORT_TRANSPORTER =
  "EDIT_SELF_TRANSPORT_TRANSPORTER";
export const EDIT_SELF_TRANSPORT_ORIGIN = "EDIT_SELF_TRANSPORT_ORIGIN";
export const EDIT_SELF_TRANSPORT_PRICE = "EDIT_SELF_TRANSPORT_PRICE";
export const EDIT_SELF_TRANSPORT_REMARK = "EDIT_SELF_TRANSPORT_REMARK";

export const EDIT_SELF_TRANSPORT_CHEQUE_NUMBER =
  "EDIT_SELF_TRANSPORT_CHEQUE_NUMBER";
export const EDIT_SELF_TRANSPORT_QUANTITY = "EDIT_SELF_TRANSPORT_QUANTITY";
export const EDIT_SELF_TRANSPORT_ORIGINAL_QUANTITY =
  "EDIT_SELF_TRANSPORT_ORIGINAL_QUANTITY";
export const EDIT_SELF_TRANSPORT_CHEQUE_ORIGINAL_AMOUNT =
  "EDIT_SELF_TRANSPORT_CHEQUE_ORIGINAL_AMOUNT";
export const EDIT_SELF_TRANSPORT_UTR_NUMBER = "EDIT_SELF_TRANSPORT_UTR_NUMBER";
export const EDIT_SELF_TRANSPORT_CHEQUE_AMOUNT =
  "EDIT_SELF_TRANSPORT_CHEQUE_AMOUNT";
export const EDIT_SELF_TRANSPORT_BANK_NAME = "EDIT_SELF_TRANSPORT_BANK_NAME";
export const EDIT_SELF_TRANSPORT_CHEQUE_DATE =
  "EDIT_SELF_TRANSPORT_CHEQUE_DATE";
export const EDIT_SELF_TRANSPORT_ACCOUNT_NAME =
  "EDIT_SELF_TRANSPORT_ACCOUNT_NAME";

// LOLO RECEIPT
export const LOLO_RECEIPT_PERFORMA_RECEIPT_DATE =
  "LOLO_RECEIPT_PERFORMA_RECEIPT_DATE";
export const LOLO_RECEIPT_INVOICE_DATE = "LOLO_RECEIPT_INVOICE_DATE";
export const LOLO_RECEIPT_CONTAINER_NUMBER = "LOLO_RECEIPT_CONTAINER_NUMBER";
export const LOLO_RECEIPT_CLIENT_NAME = "LOLO_RECEIPT_CLIENT_NAME";
export const LOLO_RECEIPT_TRUCK_NUMBER = "LOLO_RECEIPT_TRUCK_NUMBER";
export const LOLO_RECEIPT_CURRENCY = "LOLO_RECEIPT_CURRENCY";
export const LOLO_RECEIPT_DELIVERY_DATE = "LOLO_RECEIPT_DELIVERY_DATE";
export const LOLO_RECEIPT_DUE_DATE = "LOLO_RECEIPT_DUE_DATE";
export const LOLO_RECEIPT_SHIPPER = "LOLO_RECEIPT_SHIPPER";
export const LOLO_RECEIPT_CONSIGNOR = "LOLO_RECEIPT_CONSIGNOR";
export const LOLO_RECEIPT_CHARGE_TYPE = "LOLO_RECEIPT_CHARGE_TYPE";
export const LOLO_RECEIPT_DESCRIPTION = "LOLO_RECEIPT_DESCRIPTION";
export const LOLO_RECEIPT_AMOUNT = "LOLO_RECEIPT_AMOUNT";
export const LOLO_RECEIPT_REMARKS = "LOLO_RECEIPT_REMARKS";
export const LOLO_RECEIPT_GST_PERCENT_VALUE = "LOLO_RECEIPT_GST_PERCENT_VALUE";
export const LOLO_RECEIPT_CGST_PERCENT_VALUE =
  "LOLO_RECEIPT_CGST_PERCENT_VALUE";
export const LOLO_RECEIPT_SGST_PERCENT_VALUE =
  "LOLO_RECEIPT_SGST_PERCENT_VALUE";
export const LOLO_RECEIPT_IGST_PERCENT_VALUE =
  "LOLO_RECEIPT_IGST_PERCENT_VALUE";

export const LOLO_RECEIPT_CGST_AMOUNT = "LOLO_RECEIPT_CGST_AMOUNT";
export const LOLO_RECEIPT_SGST_AMOUNT = "LOLO_RECEIPT_SGST_AMOUNT";
export const LOLO_RECEIPT_IGST_AMOUNT = "LOLO_RECEIPT_IGST_AMOUNT";
export const LOLO_RECEIPT_NET_AMOUNT = "LOLO_RECEIPT_NET_AMOUNT";
export const LOLO_RECEIPT_TAXABLE_AMOUNT = "LOLO_RECEIPT_TAXABLE_AMOUNT";
export const LOLO_RECEIPT_GROSS_AMOUNT = "LOLO_RECEIPT_GROSS_AMOUNT";

// ************* G A T E    O U T *****************

// EIR DETAILS
export const GATE_OUT_SET_EIR_DATE = "GATE_OUT_SET_EIR_DATE";
export const GATE_OUT_SET_EIR_TIME = "GATE_OUT_SET_EIR_TIME";
export const GATE_OUT_SET_EIR_AMOUNT = "GATE_OUT_SET_EIR_AMOUNT";
export const GATE_OUT_SET_OFFLOAD_DATE = "GATE_OUT_SET_OFFLOAD_DATE";
export const GATE_OUT_SET_OFFLOAD_TIME = "GATE_OUT_SET_OFFLOAD_TIME";
export const GATE_OUT_SET_REPAIR_AMOUNT = "GATE_OUT_SET_REPAIR_AMOUNT";
export const GATE_OUT_SET_ADD_EIR_LINE = "GATE_OUT_SET_ADD_EIR_LINE";
export const GATE_OUT_SET_REMOVE_EIR_LINE = "GATE_OUT_SET_REMOVE_EIR_LINE";
export const GATE_OUT_SET_EIR_SHIPPING_LINE_DAMAGE_CODE =
  "GATE_OUT_SET_EIR_SHIPPING_LINE_DAMAGE_CODE";
export const GATE_OUT_SET_EIR_SHIPPING_LINE_DESCRIPTION =
  "GATE_OUT_SET_EIR_SHIPPING_LINE_DESCRIPTION";

// GATE OUT DETAILS
export const GATE_OUT_DATE = "GATE_OUT_DATE";
export const GATE_OUT_TIME = "GATE_OUT_TIME";
export const GATE_OUT_CONDITION = "GATE_OUT_CONDITION";
export const GATE_OUT_GRADE = "GATE_OUT_GRADE";
export const GATE_OUT_DEPARTED = "GATE_OUT_DEPARTED";
export const GATE_OUT_CONSIGNEE = "GATE_OUT_CONSIGNEE";
export const GATE_OUT_CUSTOMER = "GATE_OUT_CUSTOMER";
export const GATE_OUT_SHIPPER = "GATE_OUT_SHIPPER";
export const GATE_OUT_DESTINATION = "GATE_OUT_DESTINATION";
export const GATE_OUT_DELIVERY = "GATE_OUT_DELIVERY";
export const GATE_OUT_TO_LOCATION = "GATE_OUT_TO_LOCATION";
export const GATE_OUT_TO_PORT = "GATE_OUT_TO_PORT";
export const GATE_OUT_VESSEL_NAME = "GATE_OUT_VESSEL_NAME";
export const GATE_OUT_VOYAGE_NUMBER = "GATE_OUT_VOYAGE_NUMBER";
export const GATE_OUT_RO_REF = "GATE_OUT_RO_REF";
export const GATE_OUT_EXPORT_CARGO = "GATE_OUT_EXPORT_CARGO";
export const GATE_OUT_TRANSPORTER_NAME = "GATE_OUT_TRANSPORTER_NAME";

export const GATE_OUT_VEHICLE_NUMBER = "GATE_OUT_VEHICLE_NUMBER";
export const GATE_OUT_DRIVER_NAME = "GATE_OUT_DRIVER_NAME";
export const GATE_OUT_DRIVER_MOBILE = "GATE_OUT_DRIVER_MOBILE";
export const GATE_OUT_DRIVER_LICENSE = "GATE_OUT_DRIVER_LICENSE";
export const GATE_OUT_CARRIER_CODE = "GATE_OUT_CARRIER_CODE";
export const GATE_OUT_LOCATION = "GATE_OUT_LOCATION";
export const GATE_OUT_PORT_OF_LOADING = "GATE_OUT_PORT_OF_LOADING";
export const GATE_OUT_PORT_OF_DISCHARGE = "GATE_OUT_PORT_OF_DISCHARGE";
export const GATE_OUT_BOOKING_NUMBER = "GATE_OUT_BOOKING_NUMBER";
export const GATE_OUT_BOOKING_DATE = "GATE_OUT_BOOKING_DATE";
export const GATE_OUT_BOOKING_PARTY = "GATE_OUT_BOOKING_PARTY";
export const GATE_OUT_SEAL_NUMBER = "GATE_OUT_SEAL_NUMBER";
export const GATE_OUT_REMARKS = "GATE_OUT_REMARKS";
export const GATE_OUT_LICENSE_IMAGE="GATE_OUT_LICENSE_IMAGE"
export const GATE_OUT_TO_DEPOT_CODE="GATE_OUT_TO_DEPOT_CODE"
export const GATE_OUT_ROAD_RAIL_TO_LOCATION_CODE ="GATE_OUT_ROAD_RAIL_TO_LOCATION_CODE"


//GATE SEAL NUMBER LISITNG 
export const GATE_SEAL_NUMBER_LISITING = "GATE_SEAL_NUMBER_LISITING"

// GATE OUT LOLO PAYMENT
export const GATE_OUT_LOLO_APPLY_CHARGES = "GATE_OUT_LOLO_APPLY_CHARGES";
export const GATE_OUT_LOLO_CUSTOMER_NAME = "GATE_OUT_LOLO_CUSTOMER_NAME";
export const GATE_OUT_LOLO_INVOICE_DATE = "GATE_OUT_LOLO_INVOICE_DATE";
export const GATE_OUT_LOLO_RECEIPT_DATE = "GATE_OUT_LOLO_RECEIPT_DATE";
export const GATE_OUT_LOLO_TYPE = "GATE_OUT_LOLO_TYPE";
export const GATE_OUT_LOLO_PAYMENT_TYPE = "GATE_OUT_LOLO_PAYMENT_TYPE";
export const GATE_OUT_LOLO_AMOUNT = "GATE_OUT_LOLO_AMOUNT";
export const GATE_OUT_LOLO_REMARK = "GATE_OUT_LOLO_REMARK";
export const GATE_OUT_NIGHT_CHARGE = "GATE_OUT_NIGHT_CHARGE";
export const GATE_OUT_LOLO_PAYMENT_CHEQUE_NUMBER =
  "GATE_OUT_LOLO_PAYMENT_CHEQUE_NUMBER";
export const GATE_OUT_LOLO_PAYMENT_QUANTITY = "GATE_OUT_LOLO_PAYMENT_QUANTITY";
export const GATE_OUT_LOLO_PAYMENT_UTR_NO = "GATE_OUT_LOLO_PAYMENT_UTR_NO";
export const GATE_OUT_LOLO_PAYMENT_CHEQUE_AMOUNT =
  "GATE_OUT_LOLO_PAYMENT_CHEQUE_AMOUNT";
export const GATE_OUT_LOLO_PAYMENT_BANK_NAME =
  "GATE_OUT_LOLO_PAYMENT_BANK_NAME";
export const GATE_OUT_LOLO_PAYMENT_DATE = "GATE_OUT_LOLO_PAYMENT_DATE";
export const GATE_OUT_LOLO_PAYMENT_ACCOUNT_NAME =
  "GATE_OUT_LOLO_PAYMENT_ACCOUNT_NAME";

// GATE OUT SELFT TRANSPORT PAYMEN
export const GATE_OUT_SELF_TRANSPORT_APPLY_CHARGES =
  "GATE_OUT_SELF_TRANSPORT_APPLY_CHARGES";
export const GATE_OUT_SELF_TRANSPORT_CUSTOMER_NAME =
  "GATE_OUT_SELF_TRANSPORT_CUSTOMER_NAME";
export const GATE_OUT_SELF_TRANSPORT_INVOICE_DATE =
  "GATE_OUT_SELF_TRANSPORT_INVOICE_DATE";
export const GATE_OUT_SELF_TRANSPORT_ORIGIN = "GATE_OUT_SELF_TRANSPORT_ORIGIN";
export const GATE_OUT_SELF_TRANSPORT_RECEIPT_DATE =
  "GATE_OUT_SELF_TRANSPORT_RECEIPT_DATE";
export const GATE_OUT_SELF_TRANSPORT_TRANSPORTER =
  "GATE_OUT_SELF_TRANSPORT_TRANSPORTER";
export const GATE_OUT_SELF_TRANSPORT_TYPE = "GATE_OUT_SELF_TRANSPORT_TYPE";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_TYPE =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_TYPE";
export const GATE_OUT_SELF_TRANSPORT_PRICE = "GATE_OUT_SELF_TRANSPORT_PRICE";
export const GATE_OUT_SELF_TRANSPORT_REMARK = "GATE_OUT_SELF_TRANSPORT_REMARK";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_CHEQUE_NUMBER =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_CHEQUE_NUMBER";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_QUANTITY =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_QUANTITY";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_UTR_NO =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_UTR_NO";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_CHEQUE_AMOUNT =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_CHEQUE_AMOUNT";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_BANK_NAME =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_BANK_NAME";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_DATE =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_DATE";
export const GATE_OUT_SELF_TRANSPORT_PAYMENT_ACCOUNT_NAME =
  "GATE_OUT_SELF_TRANSPORT_PAYMENT_ACCOUNT_NAME";

//  ************* GATE OUT EDIT ************ //

//EDIT GATE OUT  CONTAINER DETAILS
export const EDIT_GATE_OUT_SELF_TRANSPORT_UTR_NO =
  "EDIT_GATE_OUT_SELF_TRANSPORT_UTR_NO";
export const EDIT_GATE_OUT_CLIENT_NAME = "EDIT_GATE_OUT_CLIENT_NAME";
export const EDIT_GATE_OUT_CONTAINER_NUMBER = "EDIT_GATE_OUT_CONTAINER_NUMBER";
export const EDIT_GATE_OUT_CONTAINER_SIZE = "EDIT_GATE_OUT_CONTAINER_SIZE";
export const EDIT_GATE_OUT_CONTAINER_TYPE = "EDIT_GATE_OUT_CONTAINER_TYPE";
export const EDIT_GATE_OUT_CONTAINER_PAYLOAD =
  "EDIT_GATE_OUT_CONTAINER_PAYLOAD";
export const EDIT_GATE_OUT_GROSS_WEIGHT = "EDIT_GATE_OUT_GROSS_WEIGHT";
export const EDIT_GATE_OUT_TARE_WEIGHT = "EDIT_GATE_OUT_TARE_WEIGHT";
export const EDIT_GATE_OUT_MANUFACTURING_DATE =
  "EDIT_GATE_OUT_MANUFACTURING_DATE";
export const EDIT_GATE_OUT_LEASED_BOX = "EDIT_GATE_OUT_LEASED_BOX";
export const EDIT_GATE_OUT_TO_DEPOT_CODE="EDIT_GATE_OUT_TO_DEPOT_CODE"
export const EDIT_GATE_OUT_ROAD_RAIL_TO_LOCATION_CODE="EDIT_GATE_OUT_ROAD_RAIL_TO_LOCATION_CODE";

// EDIT EIR GATE OUT
export const EDIT_GATE_OUT_REMOVE_EIR_LINE = "EDIT_GATE_OUT_REMOVE_EIR_LINE";
export const EDIT_GATE_OUT_ADD_EIR_LINE = "EDIT_GATE_OUT_ADD_EIR_LINE";

export const EDIT_GATE_OUT_EIR_DAMAGE_CODE = "EDIT_GATE_OUT_EIR_DAMAGE_CODE";
export const EDIT_GATE_OUT_EIR_LINE_DESCRIPTION =
  "EDIT_GATE_OUT_EIR_LINE_DESCRIPTION";
export const EDIT_GATE_OUT_EIR_DATE = "EDIT_GATE_OUT_EIR_DATE";
export const EDIT_GATE_OUT_EIR_TIME = "EDIT_GATE_OUT_EIR_TIME";
export const EDIT_GATE_OUT_EIR_AMOUNT = "EDIT_GATE_OUT_EIR_AMOUNT";
export const EDIT_GATE_OUT_OFFLOAD_DATE = "EDIT_GATE_OUT_OFFLOAD_DATE";
export const EDIT_GATE_OUT_OFFLOAD_TIME = "EDIT_GATE_OUT_OFFLOAD_TIME";
export const EDIT_GATE_OUT_REPAIR_AMOUNT = "EDIT_GATE_OUT_REPAIR_AMOUNT";

// EDIT GATE OUT DETAILS

export const EDIT_GATE_OUT_DATE = "EDIT_GATE_OUT_DATE";
export const EDIT_GATE_OUT_TIME = "EDIT_GATE_OUT_TIME";
export const EDIT_GATE_CONDITION = "EDIT_GATE_CONDITION";
export const EDIT_GATE_OUT_GRADE = "EDIT_GATE_OUT_GRADE";
export const EDIT_GATE_OUT_CONSIGNOR = "EDIT_GATE_OUT_CONSIGNOR";
// export const EDIT_GATE_OUT_CUSTOMER = "EDIT_GATE_OUT_CUSTOMER";
export const EDIT_GATE_OUT_SHIPPER = "EDIT_GATE_OUT_SHIPPER";
export const EDIT_GATE_OUT_DEPARTED = "EDIT_GATE_OUT_DEPARTED";
export const EDIT_GATE_OUT_DESTINATION = "EDIT_GATE_OUT_DESTINATION";
export const EDIT_GATE_OUT_DELIVERY = "EDIT_GATE_OUT_DELIVERY";
export const EDIT_GATE_OUT_TO_LOCATION_CODE = "EDIT_GATE_OUT_TO_LOCATION_CODE";
export const EDIT_TO_PORT_CODE = "EDIT_TO_PORT_CODE";
export const EDIT_GATE_OUT_VESSEL_NAME = "EDIT_GATE_OUT_VESSEL_NAME";
export const EDIT_GATE_OUT_VOYAGE_NUMBER = "EDIT_GATE_OUT_VOYAGE_NUMBER";
export const EDIT_GATE_OUT_RO_REF = "EDIT_GATE_OUT_RO_REF";
export const EDIT_GATE_OUT_EXPORT_CARGO = "EDIT_GATE_OUT_EXPORT_CARGO";
export const EDIT_GATE_OUT_TRANSPORTER_NAME = "EDIT_GATE_OUT_TRANSPORTER_NAME";
export const EDIT_GATE_OUT_VEHICLE_NUMBER = "EDIT_GATE_OUT_VEHICLE_NUMBER";
export const EDIT_GATE_OUT_DRIVER_NAME = "EDIT_GATE_OUT_DRIVER_NAME";
export const EDIT_GATE_OUT_DRIVER_MOBILE = "EDIT_GATE_OUT_DRIVER_MOBILE";
export const EDIT_GATE_OUT_DRIVER_LICENSE_NUMBER =
  "EDIT_GATE_OUT_DRIVER_LICENSE_NUMBER";
export const EDIT_GATE_OUT_CARRIER_CODE = "EDIT_GATE_OUT_CARRIER_CODE";
export const EDIT_GATE_OUT_LOCATION = "EDIT_GATE_OUT_LOCATION";
export const EDIT_GATE_OUT_PORT_OF_LOADING = "EDIT_GATE_OUT_PORT_OF_LOADING";
export const EDIT_GATE_OUT_PORT_OF_DISCHARGE =
  "EDIT_GATE_OUT_PORT_OF_DISCHARGE";
export const EDIT_GATE_OUT_BOOKING_NUMBER = "EDIT_GATE_OUT_BOOKING_NUMBER";
export const EDIT_GATE_OUT_BOOKING_DATE = "EDIT_GATE_OUT_BOOKING_DATE";
export const EDIT_GATE_OUT_BOOKING_PARTY = "EDIT_GATE_OUT_BOOKING_PARTY";
export const EDIT_GATE_OUT_SEAL_NUMBER = "EDIT_GATE_OUT_SEAL_NUMBER";

export const EDIT_GATE_OUT_REMARK = "EDIT_GATE_OUT_REMARK";
export const EDIT_GATE_OUT_LICENSE_IMAGE ="EDIT_GATE_OUT_LICENSE_IMAGE";

// EDIT GATE OUT LOLO PAYMENT

export const EDIT_GATE_OUT_LOLO_CUSTOMER_NAME =
  "EDIT_GATE_OUT_LOLO_CUSTOMER_NAME";
export const EDIT_GATE_OUT_LOLO_INVOICE_DATE =
  "EDIT_GATE_OUT_LOLO_INVOICE_DATE";
export const EDIT_GATE_OUT_LOLO_RECEIPT_DATE =
  "EDIT_GATE_OUT_LOLO_RECEIPT_DATE";
export const EDIT_GATE_OUT_LOLO_PAYMENT_TYPE =
  "EDIT_GATE_OUT_LOLO_PAYMENT_TYPE";
export const EDIT_GATE_OUT_LOLO_TYPE = "EDIT_GATE_OUT_LOLO_TYPE";
export const EDIT_GATE_OUT_LOLO_AMOUNT = "EDIT_GATE_OUT_LOLO_AMOUNT";
export const EDIT_GATE_OUT_LOLO_REMARK = "EDIT_GATE_OUT_LOLO_REMARK";
export const EDIT_GATE_OUT_NIGHT_CHARGE = "EDIT_GATE_OUT_NIGHT_CHARGE";
export const EDIT_GATE_OUT_LOLO_CHEQUE_NUMBER =
  "EDIT_GATE_OUT_LOLO_CHEQUE_NUMBER";
export const EDIT_GATE_OUT_LOLO_QUANTITY = "EDIT_GATE_OUT_LOLO_QUANTITY";
export const EDIT_GATE_OUT_LOLO_UTR_NUMBER = "EDIT_GATE_OUT_LOLO_UTR_NUMBER";
export const EDIT_GATE_OUT_LOLO_CHEQUE_AMOUNT =
  "EDIT_GATE_OUT_LOLO_CHEQUE_AMOUNT";
export const EDIT_GATE_OUT_LOLO_BANK_NAME = "EDIT_GATE_OUT_LOLO_BANK_NAME";
export const EDIT_GATE_OUT_LOLO_CHEQUE_DATE = "EDIT_GATE_OUT_LOLO_CHEQUE_DATE";
export const EDIT_GATE_OUT_LOLO_ACCOUNT_NAME =
  "EDIT_GATE_OUT_LOLO_ACCOUNT_NAME";
export const EDIT_GATE_OUT_LOLO_ORIGINAL_QUANTITY =
  "EDIT_GATE_OUT_LOLO_ORIGINAL_QUANTITY";
export const EDIT_GATE_OUT_LOLO_ORIGINAL_AMOUNT =
  "EDIT_GATE_OUT_LOLO_ORIGINAL_AMOUNT";

// GATE OUT SELF TRANSPORT EDIT
export const EDIT_GATE_OUT_SELF_TRANSPORT_CUSTOMER_NAME =
  "EDIT_GATE_OUT_SELF_TRANSPORT_CUSTOMER_NAME";
export const EDIT_GATE_OUT_SELF_TRANSPORT_RECEIPT_DATE =
  "EDIT_GATE_OUT_SELF_TRANSPORT_RECEIPT_DATE";
export const EDIT_GATE_OUT_SELF_TRANSPORT_PAYMENT_TYPE =
  "EDIT_GATE_OUT_SELF_TRANSPORT_PAYMENT_TYPE";
export const EDIT_GATE_OUT_SELF_TRANSPORT_TRANSPORTER =
  "EDIT_GATE_OUT_SELF_TRANSPORT_TRANSPORTER";
export const EDIT_GATE_OUT_SELF_TRANSPORT_ORIGIN =
  "EDIT_GATE_OUT_SELF_TRANSPORT_ORIGIN";
export const EDIT_GATE_OUT_SELF_TRANSPORT_PRICE =
  "EDIT_GATE_OUT_SELF_TRANSPORT_PRICE";
export const EDIT_GATE_OUT_SELF_TRANSPORT_REMARK =
  "EDIT_GATE_OUT_SELF_TRANSPORT_REMARK";

export const EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_NUMBER =
  "EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_NUMBER";
export const EDIT_GATE_OUT_SELF_TRANSPORT_QUANTITY =
  "EDIT_GATE_OUT_SELF_TRANSPORT_QUANTITY";
export const EDIT_GATE_OUT_SELF_TRANSPORT_ORIGINAL_QUANTITY =
  "EDIT_GATE_OUT_SELF_TRANSPORT_ORIGINAL_QUANTITY";
export const EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_ORIGINAL_AMOUNT =
  "EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_ORIGINAL_AMOUNT";
export const EDIT_GATE_OUT_SELF_TRANSPORT_UTR_NUMBER =
  "EDIT_GATE_OUT_SELF_TRANSPORT_UTR_NUMBER";
export const EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_AMOUNT =
  "EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_AMOUNT";
export const EDIT_GATE_OUT_SELF_TRANSPORT_BANK_NAME =
  "EDIT_GATE_OUT_SELF_TRANSPORT_BANK_NAME";
export const EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_DATE =
  "EDIT_GATE_OUT_SELF_TRANSPORT_CHEQUE_DATE";
export const EDIT_GATE_OUT_SELF_TRANSPORT_ACCOUNT_NAME =
  "EDIT_GATE_OUT_SELF_TRANSPORT_ACCOUNT_NAME";

//GATE OUT LOLO RECEIPT
export const GATE_OUT_LOLO_RECEIPT_PERFORMA_RECEIPT_DATE =
  "GATE_OUT_LOLO_RECEIPT_PERFORMA_RECEIPT_DATE";
export const GATE_OUT_LOLO_RECEIPT_INVOICE_DATE =
  "GATE_OUT_LOLO_RECEIPT_INVOICE_DATE";
export const GATE_OUT_LOLO_RECEIPT_CONTAINER_NUMBER =
  "GATE_OUT_LOLO_RECEIPT_CONTAINER_NUMBER";
export const GATE_OUT_LOLO_RECEIPT_CLIENT_NAME =
  "GATE_OUT_LOLO_RECEIPT_CLIENT_NAME";
export const GATE_OUT_LOLO_RECEIPT_TRUCK_NUMBER =
  "GATE_OUT_LOLO_RECEIPT_TRUCK_NUMBER";
export const GATE_OUT_LOLO_RECEIPT_CURRENCY = "GATE_OUT_LOLO_RECEIPT_CURRENCY";
export const GATE_OUT_LOLO_RECEIPT_DELIVERY_DATE =
  "GATE_OUT_LOLO_RECEIPT_DELIVERY_DATE";
export const GATE_OUT_LOLO_RECEIPT_NIGHT_CHARGES = "GATE_OUT_LOLO_RECEIPT_NIGHT_CHARGES"
export const GATE_OUT_LOLO_RECEIPT_DUE_DATE = "GATE_OUT_LOLO_RECEIPT_DUE_DATE";
export const GATE_OUT_LOLO_RECEIPT_SHIPPER = "GATE_OUT_LOLO_RECEIPT_SHIPPER";
export const GATE_OUT_LOLO_RECEIPT_CONSIGNOR =
  "GATE_OUT_LOLO_RECEIPT_CONSIGNOR";
export const GATE_OUT_LOLO_RECEIPT_CHARGE_TYPE =
  "GATE_OUT_LOLO_RECEIPT_CHARGE_TYPE";
export const GATE_OUT_LOLO_RECEIPT_DESCRIPTION =
  "GATE_OUT_LOLO_RECEIPT_DESCRIPTION";
export const GATE_OUT_LOLO_RECEIPT_AMOUNT = "GATE_OUT_LOLO_RECEIPT_AMOUNT";
export const GATE_OUT_LOLO_RECEIPT_REMARKS = "GATE_OUT_LOLO_RECEIPT_REMARKS";
export const GATE_OUT_LOLO_RECEIPT_GST_PERCENT_VALUE =
  "GATE_OUT_LOLO_RECEIPT_GST_PERCENT_VALUE";
export const GATE_OUT_LOLO_RECEIPT_CGST_PERCENT_VALUE =
  "GATE_OUT_LOLO_RECEIPT_CGST_PERCENT_VALUE";
export const GATE_OUT_LOLO_RECEIPT_SGST_PERCENT_VALUE =
  "GATE_OUT_LOLO_RECEIPT_SGST_PERCENT_VALUE";
export const GATE_OUT_LOLO_RECEIPT_IGST_PERCENT_VALUE =
  "GATE_OUT_LOLO_RECEIPT_IGST_PERCENT_VALUE";

export const GATE_OUT_LOLO_RECEIPT_CGST_AMOUNT =
  "GATE_OUT_LOLO_RECEIPT_CGST_AMOUNT";
export const GATE_OUT_LOLO_RECEIPT_SGST_AMOUNT =
  "GATE_OUT_LOLO_RECEIPT_SGST_AMOUNT";
export const GATE_OUT_LOLO_RECEIPT_IGST_AMOUNT =
  "GATE_OUT_LOLO_RECEIPT_IGST_AMOUNT";
export const GATE_OUT_LOLO_RECEIPT_NET_AMOUNT =
  "GATE_OUT_LOLO_RECEIPT_NET_AMOUNT";
export const GATE_OUT_LOLO_RECEIPT_TAXABLE_AMOUNT =
  "GATE_OUT_LOLO_RECEIPT_TAXABLE_AMOUNT";
export const GATE_OUT_LOLO_RECEIPT_GROSS_AMOUNT =
  "GATE_OUT_LOLO_RECEIPT_GROSS_AMOUNT";
